<template>
  <section id="dashboard">
    <b-row>
      <b-col>
        <h2>Clinicians</h2>
      </b-col>
    </b-row>

    <b-row class="content-header">
      <b-col
        v-if="viewMode"
        md="12"
        class="mb-2 text-right"
      >
        <button
          v-if="viewMode"
          class="btn btn-primary"
          type="button"
          @click="addClinician"
        >
          Add Clinician
        </button>
      </b-col>
      <b-col
        v-else
        md="12"
        class="mb-2 text-right"
      >
        <button
          class="btn btn-secondary mr-3"
          type="button"
          @click="cancelClinician"
        >
          Cancel
        </button>
        <button
          v-if="!cUuid"
          class="btn btn-success"
          type="button"
          @click="saveClinician"
        >
          Save
        </button>
        <button
          v-else
          class="btn btn-success"
          type="button"
          @click="updateClinician"
        >
          Save
        </button>
      </b-col>
    </b-row>

    <div
      v-if="viewMode"
      class="container mt-3 bg-white rounded p-5"
    >
      <h3 class="mb-3">
        All Clinicians
      </h3>
      <b-row class="match-height">
        <b-col>
          <div
            v-if="clinicians.length < 1"
            class="alert alert-info p-3"
          >
            No clinicians found.
          </div>
          <div
            v-else
            class="table-responsive"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in clinicians"
                  :key="item.id"
                >
                  <td @click="viewProfile(item.uuid)">
                    {{ item.uuid }}
                  </td>
                  <td @click="viewProfile(item.uuid)">
                    {{ item.name }}
                  </td>
                  <td>{{ item.email }}</td>
                  <td class="text-capitalize">
                    {{ item.role.name }}
                  </td>
                  <td>
                    <button
                      class="btn btn-primary btn-sm"
                      type="button"
                      @click="editClinician(item)"
                    >
                      Edit
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-danger btn-sm"
                      type="button"
                      @click="deleteClinician(item.uuid)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-pagination
              v-model="args.page"
              :total-rows="args.total"
              :per-page="args.size"
              align="center"
              class="my-1"
              @change="getClinicians"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="container mt-3 bg-white rounded p-5"
    >
      <h3
        v-if="!cUuid"
        class="mb-3"
      >
        Add Clinician
      </h3>
      <h3
        v-else
        class="mb-3"
      >
        Edit Clinician
      </h3>
      <b-row class="match-height">
        <b-col>
          <div class="form-group mb-3">
            <label for="clinicianName">Clinician Name</label>
            <input
              id="clinicianName"
              v-model="clinician.name"
              type="text"
              class="form-control"
              placeholder="Enter clinician name"
            >
          </div>
          <div class="form-group mb-3">
            <label for="clinicianEmail">Clinician Email</label>
            <input
              id="clinicianEmail"
              v-model="clinician.email"
              type="text"
              class="form-control"
              placeholder="Enter clinician email"
            >
          </div>
          <div class="form-group mb-3">
            <label for="clinicianPassword">Clinician Password</label>
            <input
              id="clinicianPassword"
              v-model="clinician.password"
              type="password"
              class="form-control"
              placeholder="Enter clinician password"
            >
          </div>
          <div class="form-group mb-3">
            <label for="clinicianCPassword">Confirm Password</label>
            <input
              id="clinicianCPassword"
              v-model="clinician.c_password"
              type="password"
              class="form-control"
              placeholder="confirm password"
            >
          </div>
        </b-col>
      </b-row>
    </div>

  </section>
</template>

<script>
import { BRow, BCol, BPagination } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      clinicians: [], // digest of all clinicians
      viewMode: true,
      clinician: {}, // clinician form data
      cUuid: null,
      args: {
        page: 1,
        size: 20,
        total: 100,
      },
    }
  },
  mounted() {
    this.getClinicians()
  },
  methods: {
    getClinicians() {
      useJwt.getClinicians(this.args).then(response => {
        this.clinicians = response.data.data
        this.args.total = response.data.links.last_page * this.args.size
      })
    },
    viewProfile(uuid) {
      this.$router.push({ name: 'view-clinician', params: { uuid } })
    },
    addClinician() {
      this.viewMode = false
      this.clinician = {}
    },
    editClinician(clinician) {
      this.viewMode = false
      this.clinician = clinician
      this.cUuid = clinician.uuid
    },
    cancelClinician() {
      this.viewMode = true
      this.clinician = {}
      this.cUuid = null
    },
    saveClinician() {
      useJwt.addClinician(this.clinician).then(() => {
        this.viewMode = true
        this.clinician = {}
        this.getClinicians()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Clinicians',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Clinician added successfully',
          },
        })
      },
      error => {
        let message
        if (error.response.status === 400) {
          const keys = Object.keys(error.response.data.error)
          if (keys.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            message = error.response.data.error[keys[0]][0]
          } else {
            message = error.response.data.details
          }
        } else {
          message = 'Something went wrong. Please try later.'
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Clinicians',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: message,
          },
        })
      })
    },
    updateClinician() {
      useJwt.updateClinician(this.clinician).then(() => {
        this.viewMode = true
        this.clinician = {}
        this.cUuid = null
        this.getClinicians()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Clinicians',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Clinician updated successfully',
          },
        })
      },
      error => {
        let message
        if (error.response.status === 400) {
          const keys = Object.keys(error.response.data.error)
          if (keys.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            message = error.response.data.error[keys[0]][0]
          } else {
            message = error.response.data.details
          }
        } else {
          message = 'Something went wrong. Please try later.'
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Clinicians',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: message,
          },
        })
      })
    },
    deleteClinician(clinician) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteClinician(clinician).then(() => {
            this.clinician = {}
            this.cUuid = null
            this.getClinicians()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Clinicians',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Clinician deleted successfully',
              },
            })
          },
          error => {
            let message
            if (error.response.status === 400) {
              const keys = Object.keys(error.response.data.error)
              if (keys.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                message = error.response.data.error[keys[0]][0]
              } else {
                message = error.response.data.details
              }
            } else {
              message = 'Something went wrong. Please try later.'
            }
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Clinicians',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: message,
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
// @import '@core/scss/vue/libs/chart-apex.scss';
</style>
